<template>
  <div>
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-justify">
            <h2 class="text-left mb-3 mt-4">How it Works!</h2>
            <h5>The Power of TEKA's Business Concept</h5>
            <p>When you purchase something from Teka, what else will you get in addition to that something? Most vendors usually offer some discounts on top of the actual price of the product, but does Teka offer you anything more than that? Yes, it does! Read on to know how.</p>
            <p>Here is a list of what you will get for every purchase you make through TEKA's app:</p>
            <ol style="padding-left:  15px;">
                <li> Cash Discounts of up to 40%</li>
                <li> Personal promotional Cashbacks on all of your purchases</li>
                <li> Teka Points for purchases made by your community.</li>
                <li> Group Cashbacks on your own purchases</li>
                <li> Scratch cards</li>
            </ol>
            <p>Let us look at each of these a little closely. Firstly, in addition to the product itself, Teka offers you genuine cash discounts up to 40% at all times on all products - these discounts are not dependent on seasons or festivals - they are there all the time for you to avail.</p>
            <p>Next up, Teka offers you Teka Points for your purchases, much like credit card companies offer points on the purchases you make using their credit cards. But the similarity ends there. Teka Points system is the game-changer here - these are the building blocks of TEKA's Business offer.</p>
            <p>TEKA's community building is simple - whenever Customers from your area or city or a state purchase from Teka linked Franchise outlets it forms a sort of community to your Teka identification number using Ai systems. TEKA's customers benefit from their purchases / sales - as a community marketplace that is driven by the power of acquaintances and connections. You can also refer others too, referring others is as simple as forwarding the referral link to their mobile or WhatsApp.</p>
            <p>Imagine the power of community buying. Let us see how.</p>
            <p>It is common enough and perfectly understandable when discounts and cashbacks are offered on personal purchases. But it becomes interesting when discounts and cashbacks are offered to you on purchases of your referrals.</p>
            <p>It works like this - when you buy products /sell from Teka or someone from your community or a group buys/sells products from Teka, you get an appropriate number of points as stipulated by the business. These points are called Teka Points or Akshaya Patra Points. These are the points that contribute to Teka cashbacks. In Teka, there are three types of cashbacks on Akshaya Patra Points - Personal Promotional Cashback, 100% Cashback and 400% Cashback on Teka Points.</p>
            <h5>Promotional Cashback:</h5>
            <p>When you buy products from Teka, the Akshaya Patra Points that you earn are not only added to all the people in your community, but are given as cashback to you immediately. This is called Personal Cashback. For example, if you buy a TV worth Rs.50000 and if it gives you 1000 Teka Points, then immediately after the purchase, you will receive Rs. 1000 as cashback - this is in addition to the regular discount you would have availed on the TV. This cashback is called Promotional Cashback. Promotional Cashback is purely powered by your personal purchases. Customer will also receive Referral Cash Back whenever their referred Customer purchase from TEKA linked merchants.</p>
            <h5>Group Cashbacks:</h5>
            <p>Whenever someone from your community buys products/sells/recommends, or for purchase, some points get added to your wallet as soon as the purchase is complete. These points also are called Akshaya Patra Points/ Teka Points. These points are not given out to you as cashbacks immediately, but remain in your wallet as your wallet balance. And once these points are added to your wallet, they are like money - one-point equals one Indian Rupee. So, if you have 2000 Teka Points, it is like having 2000 rupees in your wallet. It is these points that contribute to community Cashbacks. There are two types of Group Cashbacks in Teka - 100% Cashback and 400% Cashback</p>
            <h5>100% Cashback:</h5>
            <p>If your wallet has 10000 points or less, then the 100% Cashback comes into action. Whenever you purchase products through Teka, if you have less than 10000 Akshaya Patra Points in your wallet, then you will receive up to 100% of your purchase value as cashback. For example, if you have 7000 Akshaya Patra Points in your wallet and you buy goods worth Rs.2000, then you will get Rs. 2000 as cashback immediately. If you have only 1500 Akshaya Patra Points in your wallet and you buy products worth Rs. 2000, then all of your Akshaya Patra Points will be given out to you as cashback. This is 100% cashback on Teka Points. Isn't it incredible?</p>
            <h5>400% Cashback:</h5>
            <p>The 400% Cashback works similar to the 100% Cashback rule, but it kicks into action when you have more than 10000 Akshaya Patra Points in your wallet. When you have more than 10000 Akshaya Patra Points in your wallet and you make a purchase through Teka, then you will receive up to 400% of the purchase value as cashback. Let us assume that you have 12000 Akshaya Patra Points in your wallet. Now if you buy groceries for Rs. 3000 through Teka, then Akshaya Patra Points equal to four times the value of your purchase, which is 12000 points, get converted into cashback immediately. Teka calls this cashback as Dhamaka Cashback. This amount gets transferred directly into your bank account. In addition to this, the person that referred you directly will also receive the same amount as cashback as you did - Teka calls this Double Dhamaka Cashback. So, you bought products worth Rs. 3000 but received a cashback of Rs. 12000 - a cashback of 400%! Isn't this amazing?</p>
            <p>In Teka, it is perfectly natural and normal, like we mentioned above, your entire community can contribute to your Akshaya Patra Points. If you have a 1000 strong community spread across the entire breadth of India, can you imagine how many Akshaya Patra Points you will keep receiving as they keep purchasing products from Teka on a daily basis, considering the fact that every purchase/sell they make will give you some points? You won't even know from where you are receiving those points, but your wallet will keep swelling in front of your eyes - just like little drops of water make the ocean, these little additions to your wallet from your community purchases/selling will make your wallet grow - to actually create wealth for you.</p>
            <p>How is that even possible? It is possible because of the power of TEKA's Business system and community purchases/ selling.</p>
            <p>Finally, Scratch cards are surprise gifts of gratitude from Teka to you. Depending on certain conditions like the first 5 customers of the month, the first customer to cross a certain number of Akshaya Patra Points, or customers that have completed a certain total amount of purchases, the Teka system keeps throwing up surprise scratch cards to eligible customers. These scratch card amounts can be quite substantial too. And they have been designed to add some excitement and spice to your shopping experience in Teka!</p>
            <p>So, why delay?</p>
            <p>We wish you happy referring, happy buying, happy cashbacks and happy scratch cards!</p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      title1: "teka delivery policy",
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  
};
</script>
<style scoped>
.policy-content h2 {
  color: #533333;
  font-weight: 600;
}
</style>
